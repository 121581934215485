<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 md6>
        <h1>Canales</h1>
      </v-flex>
    </v-layout>

    <v-card elevation="1" class="mt-3">
      <v-simple-table dense>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Canales de notificación</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <div class="flex-grow-1"></div>
            <v-btn class="mr-2" color="primary" elevation="2" x-small @click="add()">
              <v-icon small>mdi-plus</v-icon>Añadir
            </v-btn>
          </v-toolbar>
        </template>

        <template>
          <thead>
            <tr>
              
              <th>Nombre</th>
              <th>Grupo</th>
              <th>Descripción</th>
              <th>Fecha creación</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in channels" :key="item.id">
              <td>{{ item.channelName }}</td>
              <td>{{ item.group }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.registerDate }}</td>
              <td class="text-right" style="width:118px">
                <v-btn
                  class="ml-1"
                  outlined
                  color="primary"
                  elevation="2"
                  x-small
                  @click="edit(item)"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <vue-confirm-dialog></vue-confirm-dialog>

    <v-dialog v-model="editorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Canal
        </v-card-title>
        <v-form ref="form" lazy-validation @keyup.native.enter="submit">
          <v-container>
            <v-card-text>
              <v-container class="pb-0" fluid>
                <v-row>
                  <v-col cols="12" v-show="editMode">
                    <v-text-field
                      label="id"
                      v-model="channelItem.id"
                      filled
                      readonly
                      hide-details="auto"
                      prepend-icon="mdi-identifier"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Nombre del canal"
                      v-model="channelItem.channelName"
                      ref="focusable"
                      filled
                      clearable
                      hide-details="auto"
                      :rules="requiredRule"
                      prepend-icon="mdi-access-point"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-combobox
                      label="Grupo"
                      v-model="channelItem.group"
                      filled
                      prepend-icon="mdi-account-group"
                      :items="groups"
                      hide-details="auto"
                      ref="comboBox"
                      clearable>
                    </v-combobox>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Descripción"
                      v-model="channelItem.description"
                      ref="focusable"
                      filled
                      clearable
                      hide-details="auto"
                      prepend-icon="mdi-text-box"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-container>
        </v-form>

        <v-progress-linear :indeterminate="loading" color="primary" class="mb-0">
        </v-progress-linear>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save"> Guardar </v-btn>
          <v-btn color="primary" text @click="editorDialog = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import VueConfirmDialog from "vue-confirm-dialog";

Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

export default {
  name: "Channels",
  components: {},
  data: () => ({
    requiredRule: [v => (v != null && v != "") || "Campo obligatorio"],
    editorDialog: false,
    editMode: false,
    menu: false,
    channelItem: {},
  }),
  created() {
    this.$store.dispatch("fetchChannels");
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    channels() {
      return this.$store.getters.getChannels;
    },
    groups() {
      return this.$store.getters.getChannelsGroups;
    },
  },
  methods: {
    add: function() {
      this.channelItem = {};
      this.editorDialog = true;
      this.editMode = false;
    },
    edit: function(item) {
      this.channelItem = item;
      this.editorDialog = true;
      this.editMode = true;
    },
    save: function() {
      var form = this.$refs.form;
      this.$refs["focusable"].blur();
      this.$refs.comboBox.blur();
      this.$nextTick(() => {

        if (form.validate()) {
          var action = this.channelItem.id ? "updateChannel" : "createChannel";
          this.$store
            .dispatch(action, this.channelItem)
            .then(() => {
              this.$store.dispatch("fetchChannels");
              this.editorDialog = false;
            })
            .catch(() => {
              this.editorDialog = false;
              alert("No se ha podido guardar el canal.");
            });
          }
        })      
      
    },
  },
};
</script>

<style scoped></style>
